<template>
    <div>
        <div class="page-header clear-filter" filter-color="grey-blue">
            <parallax
                    class="page-header-image"
                    style="background-image:url('img/front.png')"
            >
            </parallax>
            <div class="container">
                <div class="content-center brand">

                    <h1 class="h1-seo">ALKA</h1>
                    <h3 class="title-bold">SANITÄR &bull; HEIZUNG &bull; LÜFTUNG</h3>
                </div>

            </div>
        </div>
        <div class="section ">
            <div class="container text-center">
                <div class="row justify-content-md-center">
                    <div class="col-md-12 col-lg-8">
                        <h3 class="title text-blue">Ihr persönlicher Fachmann für Sanitär und Heizung in Zürich und
                            Region.
                        </h3>

                    </div>
                </div>
            </div>
        </div>
        <div class="section section-about-us section-about" id="ueber">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title text-blue">Über Uns</h2>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
                <div class="section-story-overview">
                    <div class="row">
                        <div class="col-md-6">
                            <div
                                    class="image-container image-left"
                                    style="background-image: url('img/tab3.jpg')"
                            >
                                <!-- First image on the left side -->
                                <p class="blockquote blockquote-primary">
                                    "Unser wichtigstes Ziel ist, dass unsere Kunden nach Vollendung eines Bauwerks auch
                                    Jahre später in jeder Beziehung zufrieden sind."
                                    <br/>
                                    <br/>
                                </p>
                            </div>
                            <!-- Second image on the left side of the article -->
                            <div
                                    class="image-container"
                                    style="background-image: url('img/mountain.jpg')"
                            ></div>
                        </div>
                        <div class="col-md-5">
                            <!-- First image on the right side, above the article -->
                            <div
                                    class="image-container image-right"
                                    style="background-image: url('img/bathroom.jpg')"
                            ></div>
                            <h3><br>

                                Qualität & Erfahrung
                            </h3>
                            <p>
                                Seit 2010 steht ALKA Sanitär – Heizung für höchste Qualitätsansprüche,
                                kundenorientiertes Arbeiten und optimale Problemlösungen – dafür widmen wir uns den
                                Technologien und Möglichkeiten von morgen.
                            </p>
                            <p>
                                Die optimale Lösung Ihrer individuellen <br> Bedürfnisse – ob im Eigenheim oder als
                                Grossprojekt – bildet stets die Basis für modernen und nachhaltigen Installationen.
                            </p>

                            <p>
                                Unsere Aufgabe ist, die vorhandene Bauressourcen mit den zur Verfügung stehenden
                                technischen Mitteln zu rationalisieren und nach ökologischen Aspekten zu realisieren.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <notifications></notifications>
        <!--carousel-section></carousel-section-->

        <div class="section section-signup section-contact-us text-center"

             style="background-image: url('img/contactbg8.jpg'); background-size: cover; background-position: top center; min-height: 700px;">

            <ContactForm></ContactForm>
        </div>


    </div>
</template>
<script>


    import {Parallax} from '@/components';


    import Notifications from './components/Notifications';


    //import CarouselSection from './components/CarouselSection';


    import ContactForm from "./components/ContactForm";


    export default {
        name: 'index',
        bodyClass: 'index-page',
        components: {
            Parallax,

            Notifications,


            //CarouselSection,
            ContactForm,


        }
    }


</script>
<style>
    .section-about {
        background-color: rgb(237, 240, 243) !important;

    }

    .title-bold {
        font-weight:700 !important;

    }
</style>
