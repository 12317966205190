<template>
  <navbar
    position="fixed"
    type="dark-blue"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
       <div class="logo">ALKA<span style="font-size:12.5px"> - Haustechnik</span></div>
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link class="nav-link
" to="/#ueber"><p>Über Uns</p>
        </router-link>
      </li>
      <drop-down
              tag="li"
              title="Dienstleistung"

              class="nav-item"
      >
        <nav-link class="btn-neutral
" to="/#beratung">
          <i class=""></i> Beratung
        </nav-link>
        <nav-link class="btn-neutral
" to="/#planung">
          <i class=""></i> Planung
        </nav-link>
        <nav-link class="btn-neutral
" to="/#umbau">
          <i class=""></i> Umbau
        </nav-link>
        <nav-link class="btn-neutral
" to="/#reparatur">
          <i class=""></i> Reparaturen
        </nav-link>
      </drop-down>
      <li class="nav-item">
        <router-link
          class="nav-link btn btn-neutral"
         to="/#kontakt"
        >
          <i class=""></i>
          <p>Kontakt</p>
        </router-link>
      </li>

      <li class="nav-item">
        <!--a
          class="nav-link"
          rel="tooltip"
          title="Follow us on Twitter"
          data-placement="bottom"
          href=""
          target="_blank"
        >
          <i class="fab fa-twitter"></i>
          <p class="d-lg-none d-xl-none">Twitter</p>
        </a-->
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          rel="tooltip"
          title="Connect on Linkedin"
          data-placement="bottom"
          href="https://www.linkedin.com/in/besnik-alka-53b288b6/"
          target="_blank"
        >
          <i class="fab fa-linkedin"></i>
          <p class="d-lg-none d-xl-none">Linkedin</p>
        </a>
      </li>
    </template>
  </navbar>
</template>

<script>
import { DropDown, Navbar, NavLink } from '@/components';
import { Popover } from 'element-ui';
export default {
  name: 'main-navbar',
  props: {
    transparent: Boolean,
    colorOnScroll: Number
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover
  }
};
</script>

<style scoped>
  .logo{
    font-size:25px;
    font-weight:600;
  }
</style>
