<template>
    <div>
        <div class="page-header page-header-small">
            <parallax
                    class="page-header-image"
                    style="background-image: url('img/abg1.jpg')"
            >
            </parallax>
        </div>
        <div class="section agb-section section-about-us">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 ml-auto mr-auto text-center">
                        <h2 class="title text-blue">Impressum</h2>
                    </div>
                </div>
                <div class="separator separator-primary"></div>
                <div class="section-story-overview">
                    <div class="row">
                        <h4>
                            Kontakt
                        </h4>
                    </div>
                    <div class="row">
                        <p>
                            ALKA Sanitär und Heizung<br>
                            Besnik Alka<br>
                            Ueberlandstrasse 370<br>
                            CH-8051 Zuerich<br>
                            Tel:079 668 16 46<br>
                            Tel:044 443 20 05 55<br>

                            Mail: info@alka-sanitaer-heizung.ch
                        </p>
                    </div>
                    <br><br>

                    <div class="row">
                        <h4>
                            Haftung für Inhalte
                        </h4>
                        <p>
                            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                            Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
                            Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
                            jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
                            oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
                            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                            allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
                            ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
                            von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                        </p>
                        <h4>
                            Haftung für Links
                        </h4>
                        <p>
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
                            Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
                            Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
                            Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
                            mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
                            nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
                            konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                            Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                        </p>
                        <h4>
                            Urheberrecht
                        </h4>
                        <p>
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                            deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
                            des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
                            privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
                            vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
                            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                            Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                        </p>
                        <h3>
                            <br><br>
                            Datenschutz
                        </h3>

                        <p>
                            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich.
                            Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder
                            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.
                            Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben.

                            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                            E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                            durch Dritte ist nicht möglich.

                            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte
                            zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien
                            wird hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich
                            rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
                            Spam-Mails, vor.
                        </p>
                        <br><br>
                        <div class="copyright">
                            &copy; {{ year }} ALKA & DIBA GmbH
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import {Button, FormGroupInput} from '@/components';

    export default {
        bodyClass: 'landing-page',
        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput
        },
        data() {
            return {
                form: {
                    firstName: '',
                    email: '',
                    message: ''
                }
            };
        }
    };
</script>
<style>
    .agb-section{
        background-color:#DFE5E9!important;
    }
</style>
