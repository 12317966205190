<template>

    <div class="container" :class="{white:white, black:!white}" >
        <div v-if="!sent && !sending">
            <h2 class="title txt-white" id="kontakt"><b>Haben wir Ihr Interesse geweckt?</b></h2>
            <p class="description txt-white"><b>Projekte, Fragen oder Interesse?</b></p>
            <div class="row" >
                <div class="col-lg-6 text-center ml-auto mr-auto col-md-8 ">
                    <form id="contactform" class="contact-form" @submit.prevent="sendEmail">
                        <fg-input
                                class="input-lg white inputbg"
                                placeholder="Name..."
                                v-model="form.from_name"
                                name="from_name"
                                addon-left-icon="now-ui-icons users_circle-08"
                        >
                        </fg-input>
                        <fg-input
                                class="input-lg white inputbg"
                                placeholder="Email..."
                                type="email"
                                name="reply_to"
                                v-model="form.reply_to"
                                addon-left-icon="now-ui-icons ui-1_email-85"
                        >
                        </fg-input>
                        <div class="textarea-container">
                    <textarea
                            class="form-control text-box inputbg"
                            name="message"
                            rows="4"
                            cols="80"
                            v-model="form.message"
                            placeholder="Schreiben Sie hier Ihre Nachricht..."
                    ></textarea>
                        </div>
                        <div class="send-button white inputbg ">
                            <n-button type="primary" form="contactform" class="btn-color-send" value="Submit" round block size="lg" @click="sendEmail"
                            >Nachricht Senden
                            </n-button
                            >
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div v-if="!sent && sending">
            Wird gesendet...
        </div>
        <div v-if="sent">
            Vielen Dank, Wir melden uns so bald wie möglich bei
            Ihnen.
        </div>
    </div>

</template>

<script>
    import {Button, FormGroupInput} from '@/components';
    import emailjs from 'emailjs-com';

    export default {
        bodyClass: 'landing-page',
        components: {
            [Button.name]: Button,
            [FormGroupInput.name]: FormGroupInput
        },
        props:{
            white:{
                type:Boolean,
                default:true
            }
        },
        data() {
            return {sent:false, sending:false,form:{}}
        },
        methods: {
            sendEmail: function(){
                this.sending = true
                const self = this
                emailjs.sendForm('service_c0smfpp', 'template_cp8kpjl', '#contactform', 'user_u0t0Gh0NABmVWGI5Xxm7y')
                    .then((result) => {
                        self.sending = false
                        self.sent = true
                       // console.log('SUCCESS!', result.status, result.text);
                    }, (error) => {
                        //console.log('FAILED...', error);
                    });
            }
        }
    };
</script>

<style scoped>
    .container{
        text-align: center;
    }



    .black{
        color:#333;
    }

    .text-box{
        border-radius:5px;
        padding-left:5px;

    }




</style>


<style>
    form .white input{
        color: #ffffff!important;
    }
   form  .input-group-focus input{
        color:#000!important;
    }

    .btn-color-send{
        background-color:#649EB1!important;
    }

    .inputbg:not(.input-group-focus) .form-control {
        background-color:rgba(0,0,0,0.6);
    }

    .inputbg.input-group-focus .input-group-text{
        background-color:#fff!important;
        color:#000;
    }
    .inputbg .input-group-text{
        background-color:rgba(0,0,0,0.6)!important;
    }

    textarea.inputbg{

        background-color:rgba(0,0,0,0.6)!important;
        color:#fff;
    }
    textarea.inputbg:focus {

        background-color:rgba(0,0,0,0.6)!important;
        color:#fff;
    }
    .text-box{
        padding-left:10px!important;
        padding-right:10px!important;
        padding-top:0px;
    }
    </style>