import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index.vue';
import Landing from './pages/Landing.vue';
import Login from './pages/Login.vue';
import Profile from './pages/Profile.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
import AGB from "./pages/components/AGB";
import Impressum from "./pages/components/Impressum";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      name: 'index',
      components: { default: Index, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path: '/agb',
      name: 'agb',
      components: { default: AGB, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, transparent:false },
        footer: { backgroundColor: 'black' }
      }
    },

    {
      path: '/Impressum',
      name: 'Impressum',
      components: { default: Impressum, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 400, transparent:false },
        footer: { backgroundColor: 'black' }
      }
    },
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 90 } };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
