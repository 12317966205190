<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul class="agb-box">
          <li>
            <router-link to="/Impressum" class="nav-link">
              Impressum
            </router-link>
          </li>
          <li>
            <router-link to="/agb" class="nav-link">
              AGB
            </router-link>
          </li>
        </ul>
        <ul>
          <li class="contactinfo">
            <b>Tel: <a href="tel:+41796681646">079 668 16 46</a>, <a href="mailto:info@alka-sanitaer-heizung.ch">info@alka-sanitaer-heizung.ch</a></b></li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }} ALKA Sanitär und Heizung
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
  .contactinfo{
   margin-left:30px;
  }

  .agb-box {
    margin-left:23px;
  }
</style>
